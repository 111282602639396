<template>
    <div id="waitinglist">
        <vue-confirm-dialog></vue-confirm-dialog>
        <Window 
            id="options-window" 
            ref="windowComponent"
            :delay="selected_queue.delay"
            @resumeDelay="resumeDelay"
            :requests="requests"
        ></Window>
        <Loader id="page-loader" v-if="loading || callnext_loading"></Loader>
        <div class="row p-0 m-0 landscape" v-if="landscape">
            <div class="col-6 first-side p-0">
                <div class="row right-logo" v-if="selected_queue.id==undefined">
                    <div class="col-3"></div>
                    <div class="col-6 logo">
                        <img :src="require('@/assets/images/Logo_Original@2x.png')" alt="" />
                    </div>
                </div>
                <div class="header"  style="padding: 5px 10px;" v-else>
                    <div class="row p-0 m-0">
                        <div class="col-10 left">
                            <div class="row p-0" style="text-align: left;">
                                <!-- <div class="col-2  p-0">
                                    <img :src="user.photo" alt="" v-if="user.photo!=''">
                                    <img :src="require('@/assets/images/1.jpg')" alt="" v-else>
                                </div> -->
                                <div class="col-12 p-0" @click="backToQueues">
                                    <b> {{ selected_queue.title }}</b>
                                    <small>
                                        <font-awesome-icon class="faCaretLeft" :icon="['fas', 'arrow-left']" />
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 right">
                            <div class="row p-0" style="text-align: right;" @click="openRequestsPopup">
                                <div class="col-2 p-0"></div>
                                <div class="col-10 p-0 notif">
                                    <font-awesome-icon class="bell" :icon="['fas', 'bell']" />
                                    <span v-if="selected_queue.pending>0">{{ selected_queue.pending }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-1"></div>
                    <!-- <div v-if="loading"  class="col-10 ticket">
                        <img  :src="require('@/assets/images/loader-circle.gif')" alt=""  style="width: 30%;margin-top: 20%;"/>
                    </div> -->
                    <div v-if="!loading && selected_queue.id!=undefined"  class="col-10 ticket">
                        <div v-if="selected_queue.totalWaiting==0 || !serving_ticket || waiting_list.length==0">
                            <div class="box-empty">
                                <img  :src="require('@/assets/images/2039107.png')" alt=""/>
                                <hr>
                                <small class="signature">Cette file d'attente est vide pour le moment</small>
                            </div>
                        </div>
                        <div v-else :class="(serving_ticket && serving_ticket.status)?'status-'+serving_ticket.status:'status-waiting'">
                            <b class="guide-button" style="color: #000;font-size: 3vh;" v-if="serving_ticket && serving_ticket.status=='waiting'">Cliquez sur "Suivant" pour commencer à servir<br><br> </b>
                            <div class="box1" :style="'background-color:'+((serving_ticket && serving_ticket.running_late)?'yellow;color: #000;':'')">
                                <span>Numéro de ticket</span><br>
                                <b>{{ serving_ticket.public_identifier }}</b><br>
                                <small v-if="serving_ticket && serving_ticket.running_late" style="color: red;">Cette personne est en retard</small>
                                <div class="circle-left"></div>
                                <div class="circle-right"></div>
                            </div>
                            <div class="box2">
                                <span class="name">{{ serving_ticket.fname }} {{ serving_ticket.lname }}</span>
                                <small class="signature">{{ serving_ticket.service_names }}</small>

                                <div class="date">{{ serving_ticket.checkinDate }} {{ serving_ticket.checkinTime }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-10 queues mb-10">
                        <div v-for="item in queues" :key="'1'+item.queue.id">
                            <div :class="'box1 queue-item queue-item-'+item.queue.id" @click="select_queue(item.queue.id, item.queue.counters.length, item.queue.counters)">
                                <span>{{ item.queue.title }}</span><hr>
                                <div class="row">
                                    <div class="col-6 left" style="text-align: right;">
                                        <small> En attente</small><b> {{ item.total_waiting }} </b>
                                    </div>
                                    <div class="col-6" style="text-align: left;">
                                        <b v-if="getHours(item.waiting_time)>0">  {{ getHours(item.waiting_time) }}   <small> hr</small></b>
                                        <b>  {{ getMinutes(item.waiting_time) }}  </b> <small> min</small>
                                    </div>
                                </div>
                            </div>
                            <div :class="'counters counters-'+item.queue.id" v-if="item.queue.counters.length>0" :style="(queues.length<=1)?'display: block;':''">
                                <select id="" class="counter-select" v-model="selected_counter">
                                    <option value="">Choisir un guichet</option>
                                    <option v-for="counter in item.queue.counters" :key="'2'+counter.id" :value="counter.id">{{counter.name}}</option>
                                </select>
                                <div class="row mt-2" v-if="selected_counter!=''">
                                    <div class="col-6">
                                        <button class="btn btn-danger close-counter" @click="closeCounter(selected_counter)">Fermer</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-success confirm-counter" @click="confirmCounter(item)">Ouvrir</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row footer" v-if="selected_queue.id!=undefined">
                    <div class="col-3"></div>
                    <div class="col-6 logo">
                        <img :src="require('@/assets/images/Logo_Original@2x.png')" alt="" />
                    </div>
                </div>
            </div>
        
            <div class="col-3 left-side left-side p-0">
                <button class="btn">
                    <small>En attente: </small><br>
                    <b>{{ selected_queue.totalWaiting }}</b>
                </button>
                <button class="btn" :style="'color: '+(selected_queue.delay?'#ffff4d':'#fff')">
                    <small>Temps d'attente: <br></small>
                    <b>
                        <span v-if="selected_queue.waiting_hours>0">{{ selected_queue.waiting_hours }} <small>hr</small> </span>
                        <span v-if="selected_queue.waiting_minutes>0 || selected_queue.waiting_seconds>0">&nbsp;{{ selected_queue.waiting_minutes }}  <small>min</small> </span>
                        <span v-if="selected_queue.waiting_seconds>0"> &nbsp; <small>{{ selected_queue.waiting_seconds }} s</small> </span>
                        <span v-if="selected_queue.waiting_minutes==0 && selected_queue.waiting_hours==0 && selected_queue.waiting_seconds==0">--</span>
                    </b>
                        <!-- <b style="color: #ffff4d" v-if="selected_queue.delay">
                            <br>
                            <span v-if="selected_queue.delay_hours>0">{{ selected_queue.delay_hours }} <small>hr</small> </span>
                            <span v-if="selected_queue.delay_minutes>0">&nbsp;{{ selected_queue.delay_minutes }}  <small>min</small> </span>
                            <span v-if="selected_queue.delay_secondes>0"> &nbsp; <small>{{ selected_queue.delay_secondes }} s</small> </span>
                            <span v-if="selected_queue.delay_minutes==0 && selected_queue.delay_hours==0 && selected_queue.delay_secondes==0">--</span>
                        </b>  -->
                    <small style="display: none;">{{selected_queue.delay}} {{selected_queue.waiting_time}}</small>
                    <span v-if="client_took_more_than_he_should" class="badge badge-danger late-danger">!</span>
                </button>
                <button class="btn">
                    <small>Servi aujourd'hui: </small><br>
                    <b>{{ selected_queue.served_today }}</b>
                </button>
                <button class="btn">
                    <small>Demandes: </small><br>
                    <b>{{ selected_queue.pending }} <!--small>demandes</small--></b>
                </button>
            </div>
            <div class="col-3 right-side p-0">
                <button class="btn btn-options" @click="optionsButton">
                    <span>Options</span>
                    <img class="icon" :src="require('@/assets/images/cog.png')" alt="">
                </button>
                <button class="btn btn-suivant" @click="nextButton" :disabled="selected_queue.id==undefined || selected_queue.totalWaiting==0">
                    <span>Suivant</span> 
                    <img class="icon" :src="require('@/assets/images/next.png')" alt="">
                    <!-- <img class="icon" :src="require('@/assets/images/loader-circle.gif')" alt=""/> -->
                </button>
                <button class="btn btn-absent" @click="absentButton" :disabled="selected_queue.id==undefined || selected_queue.totalWaiting==0">
                    <span>Absent</span>
                    <img class="icon" :src="require('@/assets/images/close.png')" alt="">
                </button>
                <button class="btn btn-fermer" @click="fermerButton" :disabled="selected_queue.id==undefined || selected_queue.totalWaiting==0 || (serving_ticket && serving_ticket.status!='inservice')">
                    <span>Fermer</span>
                    <img class="icon" :src="require('@/assets/images/close.png')" alt="">
                </button>
                <button class="btn btn-actualiser" @click="refreshButton">
                    <span>Actualiser</span>
                    <img class="icon" :src="require('@/assets/images/update.png')" alt="">
                </button>
            </div>
        </div>



        <!-- Portrait shape -->
        <div class="row p-0 m-0 portrait" v-else>
            <div class="col-12 first-side p-0">
                <div class="row right-logo" v-if="selected_queue.id==undefined">
                    <div class="col-3"></div>
                    <div class="col-6 logo">
                        <img :src="require('@/assets/images/Logo_Original@2x.png')" alt="" />
                    </div>
                </div>
                <div class="header"  style="padding: 5px 10px;" v-else>
                    <div class="row p-0 m-0">
                        <div class="col-10 left">
                            <div class="row p-0" style="text-align: left;">
                                <!-- <div class="col-2  p-0">
                                    <img :src="user.photo" alt="" v-if="user.photo!=''">
                                    <img :src="require('@/assets/images/1.jpg')" alt="" v-else>
                                </div> -->
                                <div class="col-12 p-0" @click="backToQueues">
                                    <b> {{ selected_queue.title }}</b>
                                    <small>
                                        <font-awesome-icon class="faCaretLeft" :icon="['fas', 'arrow-left']" />
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 right">
                            <div class="row p-0" style="text-align: right;" @click="openRequestsPopup">
                                <div class="col-2 p-0"></div>
                                <div class="col-10 p-0 notif">
                                    <font-awesome-icon class="bell" :icon="['fas', 'bell']" />
                                    <span v-if="selected_queue.pending>0">{{ selected_queue.pending }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-1"></div>
                    <!-- <div v-if="loading"  class="col-10 ticket">
                        <img  :src="require('@/assets/images/loader-circle.gif')" alt=""  style="width: 30%;margin-top: 20%;"/>
                    </div> -->
                    <div v-if="!loading && selected_queue.id!=undefined"  class="col-10 ticket">
                        <div v-if="selected_queue.totalWaiting==0 || !serving_ticket || waiting_list.length==0">
                            <div class="box-empty">
                                <img  :src="require('@/assets/images/2039107.png')" alt=""/>
                                <hr>
                                <small class="signature">Cette file d'attente est vide pour le moment</small>
                            </div>
                        </div>
                        <div v-else :class="(serving_ticket && serving_ticket.status)?'status-'+serving_ticket.status:'status-waiting'">
                            <b class="guide-button" style="color: #000;font-size: 3vh;" v-if="serving_ticket && serving_ticket.status=='waiting'">Cliquez sur "Suivant" pour commencer à servir<br><br> </b>
                            <div class="box1" :style="'background-color:'+((serving_ticket && serving_ticket.running_late)?'yellow;color: #000;':'')">
                                <span>Numéro de ticket</span><br>
                                <b>{{ serving_ticket.public_identifier }}</b><br>
                                <small v-if="serving_ticket && serving_ticket.running_late" style="color: red;">Cette personne est en retard</small>
                                <div class="circle-left"></div>
                                <div class="circle-right"></div>
                            </div>
                            <div class="box2">
                                <span class="name">{{ serving_ticket.fname }} {{ serving_ticket.lname }}</span>
                                <small class="signature">{{ serving_ticket.service_names }}</small>

                                <div class="date">{{ serving_ticket.checkinDate }} {{ serving_ticket.checkinTime }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-10 queues mb-10">
                        <div v-for="item in queues" :key="'3'+item.queue.id">
                            <div :class="'box1 queue-item queue-item-'+item.queue.id" @click="select_queue(item.queue.id, item.queue.counters.length, item.queue.counters)">
                                <span>{{ item.queue.title }}</span><hr>
                                <div class="row">
                                    <div class="col-6 left" style="text-align: right;">
                                        <small> En attente</small><b> {{ item.total_waiting }} </b>
                                    </div>
                                    <div class="col-6" style="text-align: left;">
                                        <b v-if="getHours(item.waiting_time)>0">  {{ getHours(item.waiting_time) }}   <small> hr</small></b>
                                        <b>  {{ getMinutes(item.waiting_time) }}  </b> <small> min</small>
                                    </div>
                                </div>
                            </div>
                            <div :class="'counters counters-'+item.queue.id" v-if="item.queue.counters.length>0">
                                <select id="" class="counter-select" v-model="selected_counter">
                                    <option value="">Choisir un guichet</option>
                                    <option v-for="counter in item.queue.counters" :key="'4'+counter.id" :value="counter.id">{{counter.name}}</option>
                                </select>
                                <div class="row mt-2" v-if="selected_counter!=''">
                                    <div class="col-6">
                                        <button class="btn btn-danger close-counter" @click="closeCounter(selected_counter)">Fermer</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-success confirm-counter" @click="confirmCounter(item)">Ouvrir</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <br>
                        <br>
                    </div>
                </div>

                <div class="row footer footer-landscape" v-if="selected_queue.id!=undefined">
                    <div class="col-3"></div>
                    <div class="col-6 logo">
                        <img :src="require('@/assets/images/Logo_Original@2x.png')" alt="" />
                    </div>
                </div>
            </div>
        
            <div class="col-12 row left-side p-0 m-0">
                <button class="btn col-6">
                    <small>En attente: </small><br>
                    <b>{{ selected_queue.totalWaiting }}</b>
                </button>
                <button class="btn col-6" :style="'color: '+(selected_queue.delay?'#ffff4d':'#fff')+';border-right: none !important;'">
                    <small>Temps d'attente: <br></small>
                    <b>
                        <span v-if="selected_queue.waiting_hours>0">{{ selected_queue.waiting_hours }} <small>hr</small> </span>
                        <span v-if="selected_queue.waiting_minutes>0 || selected_queue.waiting_seconds>0">&nbsp;{{ selected_queue.waiting_minutes }}  <small>min</small> </span>
                        <span v-if="selected_queue.waiting_seconds>0"> &nbsp; <small>{{ selected_queue.waiting_seconds }} s</small> </span>
                        <span v-if="selected_queue.waiting_minutes==0 && selected_queue.waiting_hours==0 && selected_queue.waiting_seconds==0">--</span>
                    </b>
                        <!-- <b style="color: #ffff4d" v-if="selected_queue.delay">
                            <br>
                            <span v-if="selected_queue.delay_hours>0">{{ selected_queue.delay_hours }} <small>hr</small> </span>
                            <span v-if="selected_queue.delay_minutes>0">&nbsp;{{ selected_queue.delay_minutes }}  <small>min</small> </span>
                            <span v-if="selected_queue.delay_secondes>0"> &nbsp; <small>{{ selected_queue.delay_secondes }} s</small> </span>
                            <span v-if="selected_queue.delay_minutes==0 && selected_queue.delay_hours==0 && selected_queue.delay_secondes==0">--</span>
                        </b>  -->
                    <small style="display: none;">{{selected_queue.delay}} {{selected_queue.waiting_time}}</small>
                </button>
                <button class="btn col-6" style="border-left: none !important;">
                    <small>Servi aujourd'hui: </small><br>
                    <b>{{ selected_queue.served_today }}</b>
                </button>
                <button class="btn col-6">
                    <small>Demandes: </small><br>
                    <b>{{ selected_queue.pending }} <!--small>demandes</small--></b>
                </button>
            </div>
            <div class="col-12 row right-side p-0 m-0">
                <button class="btn col btn-actualiser" @click="refreshButton">
                    <img :src="require('@/assets/images/update.png')" alt="" style="width: 50%;">
                </button>
                <button class="btn col btn-absent" @click="absentButton" :disabled="selected_queue.id==undefined || selected_queue.totalWaiting==0">
                    <img :src="require('@/assets/images/close.png')" alt="" style="width: 50%;">
                </button>
                <button class="btn col btn-suivant" @click="nextButton" :disabled="selected_queue.id==undefined || selected_queue.totalWaiting==0">
                    <img :src="require('@/assets/images/next.png')" alt="" style="width: 50%;">
                    <!-- <img class="icon" :src="require('@/assets/images/loader-circle.gif')" alt=""/> -->
                </button>
                <button class="btn col btn-options" @click="optionsButton">
                    <img :src="require('@/assets/images/cog.png')" alt="" style="width: 50%;">
                </button>
            </div>
        </div>


    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import $ from 'jquery'
import Window from '../../components/window/Window.vue'
import Loader from '../../components/loader/Loader.vue'
import { queueManagerService, queueRequestManagerService, counterManagerService } from '../../_services'
import moment from 'moment'

var getWaitingListCallBack;
var getRequestsCallBack;
var countForUserServingTime;
var audio = new Audio('/late-sound2.mp3')

export default defineComponent({
    data:()=>({
        selected_queue: {
            id: '',
            totalWaiting: '--',
            pending: '--',
            waiting_hours: 0,
            waiting_minutes: 0,
            waiting_seconds: 0,
            delay: null,
            delay_hours: 0,
            delay_minutes: 0,
            served_today: '--',
            title: '---',
            status: ''
        },
        queues: [],
        counters: [],
        selected_counter: '',
        waiting_list: {},
        serving_ticket: {},
        loading: true,
        callnext_loading: false,
        user: {},
        queue_I: false,
        requests: [],
        landscape: false,
        iCalledNext: false,
        client_has_been_in_line_for: 0,
        client_took_more_than_he_should: false
    }),
    mounted() {
        audio.pause()

        if(localStorage.getItem('user')==undefined || localStorage.getItem('user')==null || localStorage.getItem('user')=='null'){
            localStorage.removeItem('user');
            this.$router.push('/login')
        }

        this.UpdateHeights()
        this.selected_queue.id = this.$route.params.id

        this.init_page()
        if(this.$route.params.id!=undefined){
            this.getRequests()
            this.subscribeToPusher()
        }
        //Get user from localstorage
        this.user= JSON.parse( localStorage.getItem('user') ).user   
    },
    components:{
        Window,
        Loader
    },
    methods:{
        backToQueues(){
            window.location.href= '/manager_waitinglist';
        },
        closeCounter(counter_id=0){
            const self= this
            this.$confirm(
                {
                message: `Voulez-vous fermer ce guichet?`,
                button: {
                    no: 'Annuler',
                    yes: 'Oui'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm 
                 */
                callback: confirm => {
                    if (confirm) {
                        self.selected_counter= '';
                        self.callnext_loading= true;
                        var data= {}
                        if(counter_id==0){
                            data= {
                                queue: self.$route.params.id, 
                                counter_id: localStorage.getItem('counter')
                            }
                        }else{
                            data= {
                                queue: self.$route.params.id, 
                                counter_id: counter_id
                            }
                        }
                        // alert(JSON.stringify(data))

                        counterManagerService.close(data).finally(function(){
                            self.callnext_loading= false
                            self.window.location.href= '/manager_waitinglist'
                        })
                    }
                }
                }
            )
        },
        closeCounter2(){
            this.selected_counter= '';
        },
        init_page(checkInServeFromOtherCounter= false){
            const self= this;
            this.loading= true
            clearInterval(countForUserServingTime);
            audio.pause()
            this.client_has_been_in_line_for= 0;
            this.client_took_more_than_he_should= false;

            if( this.selected_queue.id != undefined ){
                this.loadWaitingList(checkInServeFromOtherCounter);
                queueManagerService.getByIdWithSummary(this.selected_queue.id).then(function(data){
                    self.selected_queue= data
                    self.selected_queue.id= data.queue_id
                    self.selected_queue.title= (data.queue.title.length<20)?data.queue.title:data.queue.title.substring(0, 19)+'...'

                    //Auto selected counter
                    data.queue.counters.map((obj) => {
                        if(obj.id==localStorage.getItem('counter')){
                            self.selected_counter= obj;
                        }
                    })
                    self.selected_queue.waiting_hours= self.getHours(data.waiting_time);
                    self.selected_queue.waiting_minutes= self.getMinutes(data.waiting_time);
                    self.selected_queue.waiting_seconds= self.getSeconds(data.waiting_time);
                    
                    self.countDown()
                }).finally(function(){
                    self.loading= false
                })
            }else{
                queueManagerService.getAllWithSummary().then(function(data){
                    console.log(data)
                    self.queues= data.queues;
                    if(data.queues.length==1){
                        console.log(data.queues[0])
                        self.select_queue(data.queues[0].queue.id, data.queues[0].queue.counters.length, data.queues[0].queue.counters)
                        // self.selected_queue= data.queues[0];
                        // self.selected_queue.id= data.queues[0].queue_id;
                        // self.selected_queue.title= data.queues[0].queue.title;
                        // self.selected_queue.title= (data.queues[0].queue.title.length<20)?data.queues[0].queue.title:data.queues[0].queue.title.substring(0, 19)+'...'

                        // //Auto selected counter
                        // data.queues[0].queue.counters.map((obj) => {
                        //     if(obj.id==localStorage.getItem('counter')){
                        //         self.selected_counter= obj;
                        //     }
                        // })
                    }
                }).finally(function(){
                    self.loading= false;
                })
            }
        },
        getRequests(id=null){
            const self= this
            queueRequestManagerService.getAll(((id)?id:this.$route.params.id)).then(function(data){
                self.requests= data.requests.map((obj)=>{
                    obj.services= obj.service_names.split(',')
                    obj.from_now= moment(obj.created_at).fromNow()
                    return obj
                })
                self.selected_queue.pending= self.requests.length
                console.log(self.requests)
            })
        },
        optionsButton(){
            $('.footer').hide();
            $('.window-component').slideDown("500");
        },
        refreshButton(){
            this.$router.go();
        },
        nextButton(){
            this.loading= true;
            clearInterval(countForUserServingTime);
            audio.pause()
            this.client_has_been_in_line_for= 0;
            this.client_took_more_than_he_should= false;

            this.callnext_loading= true
            this.iCalledNext= true
            const self= this;
            console.log(this.selected_queue.id)
            queueManagerService.callNext(this.selected_queue.id).then(function(data){
                console.log('Callnext: ')
                console.log(data)
                if(self.serving_ticket && self.serving_ticket.status=='inservice'){
                    $('.ticket').slideUp("slow")
                }else{
                    self.init_page()
                }
            }).catch(function(){
                $('.ticket').slideUp("slow", function(){
                    self.selected_queue.totalWaiting= 0
                    $('.ticket').slideDown("slow")
                    self.callnext_loading= false
                })
            }).finally(function(){
                self.callnext_loading= false
            });
        },
        absentButton(){
            this.loading= true
            // queueManagerService.revokeTicket(this.selected_queue.id, this.waiting_list[0].id).then(function(data){
            //     console.log(data);
            //     // this.init_page()
            // }.bind(this)).finally(function(){
            //     this.loading= false
            // }.bind(this));
            let data= {
                queue_line_id: this.selected_queue.id,
                public_identifier: this.waiting_list[0].public_identifier,
                rank: 1
            }
            queueManagerService.absent(data).then(function () {
                this.init_page()
                console.log('Absent')
            }.bind(this)).finally(function(){
                this.loading= false
            }.bind(this));
        },
        fermerButton(){
            this.loading= true
            queueManagerService.endTicket(this.selected_queue.id, this.waiting_list[0].public_identifier).then(function(){
                this.init_page()
                console.log('Closed')
            }.bind(this)).finally(function(){
                this.loading= false
            }.bind(this));
        },
        UpdateHeights(){
            this.landscape = $(window).width()>$(window).height()
            if(this.landscape){
                $('.right-side .btn').height( ($(window).height()-46)*0.2 )
                $('.left-side .btn').height( ($(window).height()-46)*0.25 )
                $('.queues').height( '' )
            }else{
                $('.first-side').height( ($(window).height()-46)*0.65 )
                $('.first-side').css('overflow-y', 'scroll')
                $('.first-side').css('margin-bottom', ($(window).height()-46)*0.06)
                $('.queues').height( ($(window).height()-46)*0.65 )
                $('.left-side .btn').height( ($(window).height()-46)*0.1 )
                $('.right-side .btn').height( ($(window).height()-46)*0.1 )
            }
            

           
            // $('.queues').height( ($(window).height()-46)-$('.logo').height() )
        },
        select_queue(id, counters_length, counters){
                if(counters_length>=1){
                    this.selected_counter= counters[0]
                }
                this.$router.push('manager_waitinglist/'+id);
                this.selected_queue.id= id;
                this.loading= true;
                this.init_page();
                this.loadWaitingList();
                this.subscribeToPusher(id)
                this.getRequests(id)
            // }else{
            //     if( $('.queue-item').length>=1 ){
            //         $('.queue-item:not(.queue-item-'+id+')').slideUp("slow", function(){
            //             $('.counters').hide();
            //             $('.counters-'+id).show();
            //         });
            //     }else{
            //         $('.counters').hide();
            //         $('.counters-'+id).show();
            //     }
                
            // }
        },
        getHours(seconds){
            let h= Math.floor(seconds/3600);
            return h;
        },
        getMinutes(seconds){
            let m= Math.floor((seconds%3600)/60);
            return m;
        },
        getSeconds(seconds){
            let s= Math.floor((seconds%3600)%60);
            return s;
        },
        loadWaitingList(cancelUpdate) {
            this.loading= true;
            console.log(cancelUpdate)
            const self= this;
            queueManagerService.getWaitingList(this.selected_queue.id).then(function (data) {
                console.log('waiting list:')
                console.log(data)
                    // console.log('_________', cancelUpdate,  self.serving_ticket.status=='inservice')
                // if(!(cancelUpdate && self.serving_ticket && self.serving_ticket.status=='inservice' && !self.iCalledNext)){
                    console.log('_________', 'updated')
                    // alert(self.selected_counter+'a')

                    self.waiting_list = data.waiting_list.filter((obj)=>{
                        if( obj && obj.status=='inservice' && obj.counter_id==localStorage.getItem('counter') ){
                            return true
                        }

                        if( obj && obj.status!='inservice'){
                            return true
                        }
                        
                        if( obj && obj.counter_id==-1){
                            return true
                        }

                        return false;
                    });
                    console.log('___________')
                    console.log(self.waiting_list)
                    self.serving_ticket = self.waiting_list[0];
                    //Init time spent and status
                    self.client_has_been_in_line_for= 0;
                    self.client_took_more_than_he_should= false;

                    countForUserServingTime = setInterval(function(){
                        if(self.serving_ticket && self.serving_ticket.status=='inservice'){
                            self.client_has_been_in_line_for++
                            if(self.client_has_been_in_line_for>(self.serving_ticket.estimatedTimeToSpend*60)){
                                self.client_took_more_than_he_should= true
                                if(self.client_has_been_in_line_for%10==0){
                                    audio.play()
                                }
                            }
                        }else{
                            self.client_has_been_in_line_for= 0
                            self.client_took_more_than_he_should= false
                            clearInterval(countForUserServingTime);
                            audio.pause()
                        }
                    }, 1000)

                    if(self.serving_ticket && self.serving_ticket.status && self.serving_ticket.status=='inservice'){
                        $('.ticket').slideDown("slow")
                    }
                // }
                self.iCalledNext= false
                // }
            }).finally(function(){
                self.callnext_loading= false
                this.loading= false;
            })
        },
        confirmCounter(queue){
            const self= this
            this.loading= true;
            
            counterManagerService.open(this.$route.params.id, this.selected_counter).then(function(data){
                console.log(data)
                localStorage.setItem('counter', null )

                self.$router.push('manager_waitinglist/'+queue.queue.id);
                self.selected_queue= queue;
                self.selected_queue.id= queue.queue.id;
                self.init_page();
                self.loadWaitingList();
            }).catch(function(){
                self.loading= false;
                alert('Counter already active')
            }).finally(function(){
                self.subscribeToPusher(queue.queue.id)
                self.getRequests(queue.queue.id)
            })
        },
        countDown(){
            const self= this
            // if(delay!=null){
                // delay.delay*= 60;
                // let end= moment();
                // var seconds_diff = moment.duration(end.diff(delay.created_at)).asSeconds();

                // //Delay left
                // self.selected_queue.delay.delay=  delay.delay-seconds_diff;

                // let delay_I= setInterval(() => {
                //     if(self.selected_queue.delay.delay>0 && delay){
                //         self.selected_queue.delay.delay--;
                //         self.selected_queue.delay_hours= Math.floor(self.selected_queue.delay.delay/3600);
                //         self.selected_queue.delay_minutes= Math.floor((self.selected_queue.delay.delay%3600)/60);
                //         self.selected_queue.delay_secondes= Math.floor((self.selected_queue.delay.delay%3600)%60);
                //     }else{
                //         clearInterval(delay_I)
                //         self.resumeDelay();
                //     }
                // }, 1000);
            // }else 
            if(this.waiting_list.length>0 && this.waiting_list[0] && this.waiting_list[0].status=='inservice' && !this.queue_I){
                this.queue_I= setInterval(() => {
                    if(self.selected_queue.waiting_time>0){
                        self.selected_queue.waiting_time--;
                        self.selected_queue.waiting_hours= self.getHours(self.selected_queue.waiting_time);
                        self.selected_queue.waiting_minutes= self.getMinutes(self.selected_queue.waiting_time);
                        self.selected_queue.waiting_seconds= self.getSeconds(self.selected_queue.waiting_time);
                    }else{
                        clearInterval(self.queue_I)
                        self.queue_I= false
                    }
                }, 1000);
            }
        },
        resumeDelay(){
            const self= this
            this.loading= true

            queueManagerService.resumeService(this.selected_queue.id).then(function(data){
                console.log(data)
            }).finally(function(){
                $('.window-component').slideUp("500");
               
                self.init_page()
            })
        },
        subscribeToPusher(id=null){
            getWaitingListCallBack = function () {
                console.log('pusher: ticket updated')
                this.init_page(true)
            }.bind(this);
            getRequestsCallBack = function () {
                console.log('pusher: request updated')
                this.getRequests()
            }.bind(this);
            // this.$pusher.subscribe("private-queue-" + ((id)?id:this.$route.params.id));
            this.$pusher.channel("private-queue-" + (id ? id : this.$route.params.id)).listen(".ticket-created",  getWaitingListCallBack, this);
      this.$pusher.channel("private-queue-" + (id ? id : this.$route.params.id)).listen(".ticket-deleted",  getWaitingListCallBack, this);
      this.$pusher.channel("private-queue-" + (id ? id : this.$route.params.id)).listen(".queue-request-created",  getRequestsCallBack, this);
      this.$pusher.channel("private-queue-" + (id ? id : this.$route.params.id)).listen(".queue-request-deleted",  getRequestsCallBack, this);
            // this.$pusher.bind("ticket-created", getWaitingListCallBack, this);
            // this.$pusher.bind("ticket-deleted", getWaitingListCallBack, this);
            // this.$pusher.bind("queue-request-created", getRequestsCallBack, this);
            // this.$pusher.bind("queue-request-deleted", getRequestsCallBack, this);
        },
        unsubscribeFromPusher(id) {
            this.$pusher.channel("private-queue-" + (id ? id : this.$route.params.id)).stopListening(".ticket-created",  getWaitingListCallBack, this);
      this.$pusher.channel("private-queue-" + (id ? id : this.$route.params.id)).stopListening(".ticket-deleted",  getWaitingListCallBack, this);
      this.$pusher.channel("private-queue-" + (id ? id : this.$route.params.id)).stopListening(".queue-request-created",  getRequestsCallBack, this);
      this.$pusher.channel("private-queue-" + (id ? id : this.$route.params.id)).stopListening(".queue-request-deleted",  getRequestsCallBack, this);
            // this.$pusher.unsubscribe("private-queue-" + this.$route.params.id);
            // this.$pusher.unbind("ticket-created", getWaitingListCallBack);
            // this.$pusher.unbind("ticket-deleted", getWaitingListCallBack);
            // this.$pusher.unbind("queue-request-created", getRequestsCallBack);
            // this.$pusher.unbind("queue-request-deleted", getRequestsCallBack);
        },
        openRequestsPopup(){
            this.$refs.windowComponent.showPopupRequests("success", "Demandes en attente", '', '', 'Demandes en attente')
        }
        // backToQueues(){
        //     this.$router.push('/waitinglist');
        //     this.$router.go();
        //     this.selected_queue.id = undefined
        //     this.selected_counter= ''
        //     this.init_page()
        // }
    },
    created() {
        window.addEventListener("resize", this.UpdateHeights);
    },
    beforeDestroy() {
        this.unsubscribeFromPusher(this.$route.params.id);
        counterManagerService.close(this.$route.params.id, localStorage.getItem('counter'))
    },
    async accept() {
        this.showUpdateUI = false;
        await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    }
})
</script>

<style scoped>
.late-danger{
    margin-left: 10px;
}
</style>